<template>
  <div>
    <DVVBanner />
    <Navigation />
    <Landing />
    <Highlight />
    <About />
    <Insurance />
    <Credits />
    <Saving />
    <IndependentsSme />
    <Contact />
    <Footer />
  </div>
</template>

<script>
import DVVBanner from './components/DVVBanner.vue'
import Navigation from './components/Navigation.vue'
import Landing from './components/Landing.vue'
import Highlight from './components/highlight.vue'
import About from './components/About.vue'
import Insurance from './components/Insurance.vue'
import Credits from './components/Credits.vue'
import Saving from './components/Saving.vue'
import IndependentsSme from './components/IndependentsSme.vue'
import Contact from './components/Contact.vue'
import Footer from './components/Footer.vue'



export default {
  name: 'App',
  components: {
    DVVBanner,
    Navigation,
    Landing,
    Highlight,
    Insurance,
    Credits,
    IndependentsSme,
    Saving,
    About,
    Contact,
    Footer
  }
}
</script>

<style >
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}
</style>
