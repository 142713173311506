<template>
    <nav class="navigation">

        <div id="desktop">
            <a href="">
                <div class="logo">
                    Zakenkantoor
                    <span class="large-font orange">Verleyen</span>
                </div>
            </a>
            <ul class="nav">
                <li class="hor"><a href="#about">Over Ons</a></li>
                <li class="hor"><a href="#insurance">Verzekeringen</a></li>
                <li class="hor"><a href="#credits">Kredieten</a></li>
                <li class="hor"><a href="#saving">Sparen & Beleggen</a></li>
                <li class="hor"><a href="#independentssme">Zelfstandigen & KMO's</a></li>
                <li class="hor"><a href="#contact">Contact</a></li>
                <li v-if="isVijve" class="hor"><a href="/olsene">Kantoor Olsene</a></li>
                <li v-else class="hor"><a href="/vijve">Kantoor Vijve</a></li>
            </ul>

        </div>
        <div id="mobile">
            <a href="">
                <div class="logo">
                    <span class="black">Zakenkantoor</span>
                    <span class="large-font orange">Verleyen</span>
                </div>
            </a>
            <div id="openNav" v-on:click="toggle = !toggle">
                <i class="fas fa-bars"></i>
            </div>
            <ul v-show='toggle' class="dropdown">
                <li v-on:click="toggle = !toggle"><a href="#about">Over Ons</a></li>
                <li v-on:click="toggle = !toggle"><a href="#insurance">Verzekeringen</a></li>
                <li v-on:click="toggle = !toggle"><a href="#credits">Kredieten</a></li>
                <li v-on:click="toggle = !toggle"><a href="#saving">Sparen & Beleggen</a></li>
                <li v-on:click="toggle = !toggle"><a href="#independentssme">Zelfstandigen & KMO's</a></li>
                <li v-on:click="toggle = !toggle"><a href="#contact">Contact</a></li>
                <li v-if="isVijve" v-on:click="toggle = !toggle"><a href="/olsene">Kantoor Olsene</a></li>
                <li v-else v-on:click="toggle = !toggle"><a href="/vijve">Kantoor Vijve</a></li>

            </ul>
        </div>
    </nav>
</template>


<script>

export default {

    data() {
        return {
            isVijve:Boolean,
            toggle: false
        }
    },

    methods: {
      
    },
    mounted() {
    },
    created() {
        this.isVijve = this.$router.history.current.path.includes("vijve")
    }


}
</script>


<style >
@font-face {
    font-family: semibold;
    src: url("../assets/fonts/Ciutadella-SemiBold.otf");
}




.dropdown {
    -webkit-box-shadow: 0px 6px 9px 0px rgba(36, 36, 36, 0.15);
    -moz-box-shadow: 0px 6px 9px 0px rgba(36, 36, 36, 0.15);
    box-shadow: 0px 6px 9px 0px rgba(36, 36, 36, 0.15);
}

.dropdown li {
    padding: 10px;
    background-color: #f7f7f7;
    font-size: 1.2em;
    padding-right: 150px;
}

ul.nav li:last-of-type {
    padding-right: 0px;
}



.logo {
    float: left;
    margin-left: 150px;
    text-align: left;
    margin-top: 10px;
}

.black {
    color: black;
}

.orange {
    color: #ff9e16;
}

#desktop {
    display: block;

}

#mobile {
    display: none;
    text-align: right;
}

.large-font {
    font-size: 1.8em;
    display: block;

}

#openNav {
    margin-right: 150px;
}

/* Tablet */
@media screen and (max-width: 1715px) {


    #desktop {
        display: none;
    }

    #mobile {
        display: block;
        text-align: right;

    }



}

@media screen and (max-width: 575px) {
    .logo {
        margin-left: 5px;
        min-width: 250px;
    }

    #openNav {
        margin-right: 15px;
    }

    .dropdown li {
        padding-right: 15px;
    }

}



/* Mobile */
@media screen and (max-width: 768px) {}
</style>


<style scoped>
a {
    text-decoration: none;
}

a {
    color: black;
}

a:hover {
    color: #ff9e16;
}

.logo:hover {
    color: #ff9e16;

}

#openNav i {
    padding-top: 15px;
    font-size: 2.0em;
}

#openNav:hover{
    cursor: pointer;
}


@font-face {
    font-family: semibold;
    src: url("../assets/fonts/Ciutadella-SemiBold.otf");
}


.navigation {
    position: -webkit-sticky;
    /* Safari & IE */
    position: sticky;
    top: 0;
    font-family: semibold;
    height: 60px;
    width: 100%;
    background-color: #f7f7f7;
    -webkit-box-shadow: 0px 6px 9px 0px rgba(36, 36, 36, 0.15);
    -moz-box-shadow: 0px 6px 9px 0px rgba(36, 36, 36, 0.15);
    box-shadow: 0px 6px 9px 0px rgba(36, 36, 36, 0.15);
    z-index: 10;
}

.nav {
    float: right;
    margin-top: 30px;
    list-style: none;
    margin-right: 150px;
}


.dropdown li:nth-child(odd) {
    background-color: white;
}

.dropdown li:first-of-type {
    margin-top: 12px;
}


.hor {
    display: inline;
    font-size: 1.2em;
    padding-left: 50px;
    padding-right: 50px;
}
</style>