var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"navigation"},[_c('div',{attrs:{"id":"desktop"}},[_vm._m(0),_c('ul',{staticClass:"nav"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),(_vm.isVijve)?_c('li',{staticClass:"hor"},[_c('a',{attrs:{"href":"/olsene"}},[_vm._v("Kantoor Olsene")])]):_c('li',{staticClass:"hor"},[_c('a',{attrs:{"href":"/vijve"}},[_vm._v("Kantoor Vijve")])])])]),_c('div',{attrs:{"id":"mobile"}},[_vm._m(7),_c('div',{attrs:{"id":"openNav"},on:{"click":function($event){_vm.toggle = !_vm.toggle}}},[_c('i',{staticClass:"fas fa-bars"})]),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.toggle),expression:"toggle"}],staticClass:"dropdown"},[_c('li',{on:{"click":function($event){_vm.toggle = !_vm.toggle}}},[_c('a',{attrs:{"href":"#about"}},[_vm._v("Over Ons")])]),_c('li',{on:{"click":function($event){_vm.toggle = !_vm.toggle}}},[_c('a',{attrs:{"href":"#insurance"}},[_vm._v("Verzekeringen")])]),_c('li',{on:{"click":function($event){_vm.toggle = !_vm.toggle}}},[_c('a',{attrs:{"href":"#credits"}},[_vm._v("Kredieten")])]),_c('li',{on:{"click":function($event){_vm.toggle = !_vm.toggle}}},[_c('a',{attrs:{"href":"#saving"}},[_vm._v("Sparen & Beleggen")])]),_c('li',{on:{"click":function($event){_vm.toggle = !_vm.toggle}}},[_c('a',{attrs:{"href":"#independentssme"}},[_vm._v("Zelfstandigen & KMO's")])]),_c('li',{on:{"click":function($event){_vm.toggle = !_vm.toggle}}},[_c('a',{attrs:{"href":"#contact"}},[_vm._v("Contact")])]),(_vm.isVijve)?_c('li',{on:{"click":function($event){_vm.toggle = !_vm.toggle}}},[_c('a',{attrs:{"href":"/olsene"}},[_vm._v("Kantoor Olsene")])]):_c('li',{on:{"click":function($event){_vm.toggle = !_vm.toggle}}},[_c('a',{attrs:{"href":"/vijve"}},[_vm._v("Kantoor Vijve")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":""}},[_c('div',{staticClass:"logo"},[_vm._v(" Zakenkantoor "),_c('span',{staticClass:"large-font orange"},[_vm._v("Verleyen")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"hor"},[_c('a',{attrs:{"href":"#about"}},[_vm._v("Over Ons")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"hor"},[_c('a',{attrs:{"href":"#insurance"}},[_vm._v("Verzekeringen")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"hor"},[_c('a',{attrs:{"href":"#credits"}},[_vm._v("Kredieten")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"hor"},[_c('a',{attrs:{"href":"#saving"}},[_vm._v("Sparen & Beleggen")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"hor"},[_c('a',{attrs:{"href":"#independentssme"}},[_vm._v("Zelfstandigen & KMO's")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"hor"},[_c('a',{attrs:{"href":"#contact"}},[_vm._v("Contact")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":""}},[_c('div',{staticClass:"logo"},[_c('span',{staticClass:"black"},[_vm._v("Zakenkantoor")]),_c('span',{staticClass:"large-font orange"},[_vm._v("Verleyen")])])])
}]

export { render, staticRenderFns }