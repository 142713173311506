<template>
<div class="highlights">
    <div class="highlight" v-bind:key="doc" v-for="doc in docs" >
        <span class="ico" v-html="doc.ico" >{{doc.ico}}</span>
        <p class="title">{{doc.title}}</p>
        <p  v-html="doc.content" class="content">{{doc.content}}</p>
        <a  v-bind:href="doc.urlSrc" class="fastlink">{{doc.urlTitle}}</a>
    </div>
</div>
</template>

<script>
import { db } from '../db'

export default {
    data() {
    return {
        docs:[]
    };
  },
  
  created(){
    db.collection('highlight').get().then(querySnapshot => {
         querySnapshot.docs.map(doc => this.docs.push(doc.data()))
  })
  }
}
</script>


<style>

.highlight{
    width:390px;
    background-color: white;
    text-align: left;
    display:inline-block;
    margin-left:15px;
    margin-right:15px;
    height:500px;
    max-height: 500px;
    border: solid 1px whitesmoke;
    border-bottom: solid 10px whitesmoke;
}

@media screen and (max-width: 575px) {
.highlight{
    width:auto;
    background-color: white;
    text-align: left;
    display:inline-block;
    margin-left:5%;
    margin-right:5%;
    height:500px;
    max-height: 500px;
    border: solid 1px whitesmoke;
    border-bottom: solid 10px whitesmoke;
}

.fastlink{
    
}

}


</style>


<style scoped>

@font-face {
  font-family: medium;
  src: url("../assets/fonts/Ciutadella-Medium.otf");
}

@font-face {
  font-family: regular;
  src: url("../assets/fonts/Ciutadella-Regular.otf");
}

.ico{
                text-align: center;
padding:25px;
    display: block;
          color: #ff9e16;
          font-size: 2.4em;
}

a{
    text-decoration: none;
        text-align: left;
     font-family: regular;
    font-size:1.4em;
      text-transform: uppercase;
      display: block;
      margin-top:1.5rem;
      color: #002d7d;
          margin-left: 25px;

}

a:hover{
    text-decoration: underline;
}



.title{
    font-family: medium;
    font-size:1.8em;
    color:#002d7d;
    margin-left: 25px;
    margin-right: 25px;
    padding-bottom: 25px;
}

.content{
    font-family: regular;
    margin-left: 25px;
    margin-right: 25px;
    font-size:1.2em;
      line-height: 150%;

    color:#757575;

}

.highlights{
    width:100%;
        margin-top: -150px;
    min-height: 600px;
}

</style>