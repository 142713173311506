<template>
  <div id="contact">
    <h2>Contact</h2>
    <h3>
      Afspraak maken? Graag meer informatie? <br /> Neem gerust contact op met ons
      kantoor
    </h3>
    <form id ="form" @submit.prevent="sendEmail">
      <label for="vnaam">Voornaam<span class="required">*</span></label><br />
      <input type="text" id="vnaam" name="vnaam" value="" required /><br /><br />

      <label for="naam">Naam<span class="required">*</span></label><br />
      <input type="text" id="naam" name="naam" value="" required /><br /><br />

      <label for="email">E-mail<span class="required">*</span></label><br />
      <input type="email" id="email" name="email" value="" required /><br /><br />

      <label for="gsm">GSM</label><br />
      <input type="tel" id="gsm" name="gsm" /><br /><br />

      <label for="bericht">Bericht</label><br />
      <textarea id="bericht" name="bericht" rows="8" cols="42"> </textarea>
      <input type="submit" value="Verzenden" />
    </form>
    <div class="flex">
      <div class="open">
        <p class="title">Openingsuren</p>
        <ul>
          <li v-bind:key="doc" v-for="doc in docs"><span class="day">{{ doc.day }}</span> <span
              class="hours">{{ doc.morning }} - {{ doc.afternoon }}</span></li>
        </ul>
        <p v-if="isVijve" class="extra">* Op woensdag en vrijdagnamiddag is VDK bank gesloten.</p>
        <p v-if="isVijve" class="extra">* Tijdens de maanden juli en augustus is het kantoor op zaterdag gesloten.</p>
      </div>

      <div v-if="isVijve" class="map">
        <iframe id="gmap_canvas"
          src="https://maps.google.com/maps?q=vdk%20bank%20sint%20eloois%20vijve&t=&z=13&ie=UTF8&iwloc=&output=embed"
          frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
      </div>
      <div v-else class="map">
        <iframe id="gmap_canvas"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2514.357433342965!2d3.4649575999999995!3d50.935602499999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c31519e5236875%3A0x209ffc77ba3f8c61!2sGrote%20Steenweg%2075%2C%209870%20Zulte!5e0!3m2!1sen!2sbe!4v1692151188210!5m2!1sen!2sbe"
          frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
      </div>
    </div>



  </div>
</template>

<script>
import emailjs from 'emailjs-com';

import { db } from '../db'

export default {

  methods: {
    sendEmail: () => {
      emailjs.sendForm('service_l7hxw9w', 'template_z5xcvis', "#form", "E3PfvoG_UN7TaiwZQ")
        .then(() => {
          location.reload(true);
        });
    }
  },

  data() {
    return {
      docs: [],
      isVijve: Boolean,
    };
  },

  created() {
    this.isVijve = this.$router.history.current.path.includes("vijve")
    if (this.$router.history.current.path.includes("vijve")) {
      db.collection('open').get().then(querySnapshot => {
        querySnapshot.docs.map(doc => this.docs.push(doc.data()))
      })
    } else if (
      this.$router.history.current.path.includes("olsene")
    ) {
      db.collection('openOlsene').get().then(querySnapshot => {
        querySnapshot.docs.map(doc => this.docs.push(doc.data()))
      })
    }

  }
}
</script>

<style>
input[type="submit"] {
  display: block;
  color: white;
  font-family: semibold;
  background-color: #002d7d;
  border: none;
  font-size: 1.6em;
  height: 40px;
  width: 400px;
  margin-top: 15px;
  letter-spacing: 0.1em;
  padding-top: 3px;
}

input[type="submit"]:hover {
  background-color: #feaa3f;
  cursor: pointer;
}

.flex {
  display: flex;
}

.open {
  text-align: center;
  border-top: solid #feaa3f 12px;
  height: 450px;
  width: 400px;
  background-color: white;
  border-bottom: solid 10px #e2e2e2;
  margin-left: 150px;
  vertical-align: top;
  margin-right: 30px;
}

.map {
  display: flex;
  flex-direction: column;
  text-align: left;
  border-top: solid #feaa3f 12px;
  height: 450px;
  min-width: 400px;
  border-bottom: solid 10px #e2e2e2;
  vertical-align: top;
  flex-grow: 100;
  margin-right: 150px;
}

iframe {
  border: none;
  height: 450px;
  flex-grow: 1;
}


h2 {
  font-size: 2em;
  margin-top: 30px;
  font-family: semibold;
  margin-bottom: 15px;
  color: #002d7d;
  margin-left: 150px;
}

h3 {
  font-size: 1.4em;
  font-family: regular;
  color: #002d7d;
  margin-left: 150px;
}

form {
  margin-top: 30px;
  margin-left: 150px;
  margin-bottom: 30px;
}

@media screen and (max-width: 1120px) {
  .flex {
    display: block;
  }

  .open {
    min-width: 400px;
    display: block;
  }


  .map {
    display: block;
    max-width: 400px;
    margin-top: 50px;
    margin-left: 150px;

  }

  iframe {
    width: 400px;
  }

}

@media screen and (max-width: 575px) {

  h2 {
    font-size: 1.4em;
    ;
  }


  h3 {
    font-size: 1em;
  }

  h2,
  h3 {
    max-width: 350px;
    margin-left: 5px;
  }

  form {
    margin-top: 50px;
    margin-left: 5px;
    margin-bottom: 100px;
  }

  .open {
    margin-left: 5px;
    min-width: 0px;
    width: 350px;
    height: auto;
  }


  .map {
    margin-left: 5px;
    min-width: 0px;
    width: 350px;
  }

  iframe {
    max-width: 350px;
  }

  input[type="submit"] {
    width: 350px;
  }

}
</style>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@font-face {
  font-family: semibold;
  src: url("../assets/fonts/Ciutadella-SemiBold.otf");
}

@font-face {
  font-family: regular;
  src: url("../assets/fonts/Ciutadella-Regular.otf");
}

.extra {
  font-family: regular;
  text-align: left;
  margin-left: 25px;
  margin-top: 20px;
}

.open li {
  font-family: regular;
  margin-top: 35px;
  text-align: left;
  margin-left: 25px;
}

.day {
  display: inline-block;
  width: 300px;
}

.hours {
  text-align: right;
}

.title {
  font-family: semibold;
  color: #feaa3f;
  letter-spacing: 0.1em;
  font-size: 1.2em;
  text-transform: uppercase;
  margin-top: 15px;
}



#contact {
  scroll-margin-top: 6em;
  text-align: left;
}

label {
  font-size: 1.2em;
  font-family: semibold;
  color: #002d7d;
  letter-spacing: 0.1em;
}

.required {
  color: #ff9e16;
}
</style>
