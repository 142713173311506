import Vue from 'vue'
import VueRouter from 'vue-router'
import Location from './Location.vue'
import App from './App.vue'
import Screen from './Screen.vue'


Vue.use(VueRouter)

const routes = [
  { path: '/', component: Location },
  { path: '/vijve', component: App },
  { path: '/olsene', component: App }

]

const router = new VueRouter({
  mode: 'history',
  routes
})

new Vue({
  router,
  render: function (createElement) {
    return createElement(Screen)
  }
}).$mount('#app')

